window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}

gtag('js', new Date());

document.addEventListener("turbo:load", function (event) {
    gtag('config', 'UA-113135823-28', {
        page_location: event.detail.url
    });
});

export default gtag